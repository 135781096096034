import React, { useEffect, useState } from 'react';
import * as S from './Login.styles';
import {
  CREATOR_LANDING_FUNC,
  FORGOT_PASSWORD,
  PROFILE,
} from 'constants/routes';
import Link from 'next/link';
import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import {
  fetchUserMetadata,
  fetchUserProfile,
  useUserProfile,
} from 'queries/user';
import { UserMetaDataResponse } from '@solin-fitness/types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Aside } from 'components/Aside';
import Header, { HeaderVariant } from 'shared/Header';
import TextInput from 'shared/TextInput';
import Button from 'shared/Button';
import Spacer, { Size } from 'shared/Spacer';
import Typography, { TypeVariant } from 'shared/Typography';
import Head from 'next/head';
import useAuthStore from 'hooks/useAuthStore';
import useLogin from './queries';

const initialLoginValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object({
  email: yup.string().required().min(1),
  password: yup.string().required().min(1),
});

const Login = () => {
  const [error, setError] = useState('');
  const [navigating, setNavigating] = useState(false);
  const queryClient = useQueryClient();
  const router = useRouter();
  const { logUserIn, isSignedIn } = useAuthStore();
  const login = useLogin();
  const { data: userProfile } = useUserProfile();

  useEffect(() => {
    if (isSignedIn && !!userProfile && !navigating) {
      router.push(PROFILE);
    }
  }, [userProfile]);

  /**
   * Cases
   * 1. XXX user has no subscription -> go to profile
   * 2. user has subscription -> go to creator subscribed
   * 3. user has multiple subscriptions -> go to creator subscribed (default to 0 index)
   * NOTE: case #2 and #3 would both be handled by the second condition
   * 4. user has purchased class -> go to profile page and pull creator of purchased class
   * 5. user has multiple purchased classes ???
   * @param meta - user's metadata (contains susbcripitons, purchased classes)
   */
  const handleLoginNavigation = (
    userType: number,
    meta: UserMetaDataResponse,
    pageUrl?: string,
  ) => {
    const { subscriptions } = meta;
    if (userType === 1) {
      // inactive creator (i.e. normal consumer)
      if (!!subscriptions.length) {
        const latestSub = subscriptions[0];
        router.push(CREATOR_LANDING_FUNC(latestSub.pageUrl));
      } else {
        router.push(PROFILE);
      }
    } else if (pageUrl) {
      // admin or active creator
      router.push(PROFILE);
    } else {
      router.push(PROFILE);
    }
  };

  return (
    <>
      <Head>
        <title>Solin - The Home of Social Fitness</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          property="og:title"
          content="Solin - The Home of Social Fitness"
          key="title"
        />
        <meta
          name="description"
          key="description"
          content="Already a member? Sign in to your account here!"
        />
      </Head>
      <S.Wrapper>
        <Header variant={HeaderVariant.dark} />

        <S.Container>
          <S.Logo>solin</S.Logo>

          <Formik
            initialValues={initialLoginValues}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);
              try {
                const {
                  data: { userId, userType },
                  pageUrl,
                } = await login.mutateAsync(
                  {
                    email: data.email,
                    password: data.password,
                  },
                  {
                    onSuccess: (res) => {
                      // send heap track api call to record custom event for successful log in
                      window.heap?.track('Log in Successfully', {
                        id: res.data.userId,
                        email: data.email,
                        user_type: res.data.userType,
                      });
                      setNavigating(true);
                    },
                    onError: (err: any) => {
                      setError(
                        err.message || 'Something went wrong. Please try again',
                      );
                    },
                  },
                );

                // I think that instead of relying on the user's data upon login,
                // to then navigate to one of many pages, we should navigate them to
                // a consistent page that shows them what they have available and then
                // where else they should go
                const profilePromise = fetchUserProfile();
                const metadataPromise = fetchUserMetadata();

                const [profile, metadata] = await Promise.all([
                  profilePromise,
                  metadataPromise,
                ]);

                // Prefetch data that is required on nearly every page once logged in
                queryClient.setQueryData('metadata', metadata);
                queryClient.setQueryData('profile', profile);

                // Route user to proper destination
                logUserIn(userId, userType);
                handleLoginNavigation(userType, metadata, pageUrl);
                setSubmitting(false);
              } catch (err) {
                // handle error
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => {
              const buttonEnabled =
                values.email.length > 0 && values.password.length > 0;
              return (
                <S.FormContainer>
                  {!!error && <Aside isError>{error}</Aside>}
                  <TextInput
                    fullWidth
                    label="Email"
                    value={values.email}
                    onChange={(val) => setFieldValue('email', val)}
                    variant="rounded"
                    background="light"
                    autoCapitalize={false}
                    dataTest="email"
                  />
                  <TextInput
                    fullWidth
                    type="password"
                    label="Password"
                    value={values.password}
                    onChange={(val) => setFieldValue('password', val)}
                    variant="rounded"
                    background="light"
                    dataTest="password"
                  />
                  <Spacer size={Size.spacing4} />
                  <Button
                    size="large"
                    variant="outline"
                    disabled={!buttonEnabled}
                    fullWidth
                    isLoading={isSubmitting}
                    dataTest="submit"
                  >
                    Sign In
                  </Button>

                  <Link href={FORGOT_PASSWORD} passHref>
                    <S.SecondaryTextWrap
                      style={{
                        cursor: 'pointer',
                        marginTop: 5,
                      }}
                    >
                      <Typography
                        variant={TypeVariant.body01}
                        as="p"
                        color="var(--color-white)"
                        textAlign="center"
                      >
                        Forgot password ?
                      </Typography>
                    </S.SecondaryTextWrap>
                  </Link>
                </S.FormContainer>
              );
            }}
          </Formik>
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default Login;
